import { Notification as NidNotification } from "nid-common";
import {
  OPERATION_API_NOTIFICATION,
  getNotification,
} from "nid-common/api/operation";
import { Box } from "nikkei-ui";
import Markdown from "react-markdown";
import useSWR from "swr";
import styles from "./Notification.module.css";

const ONE_DAY = 24 * 60 * 60 * 1000;

export const Notification = () => {
  const { data: response } = useSWR(
    OPERATION_API_NOTIFICATION,
    getNotification,
  );
  if (!response?.data || !response.data.message) return <></>;

  const showUntilDate = new Date(response.data.show_until_date);
  const showAfterDate = new Date(response.data.show_after_date);
  const validate = (date: Date, def: Date) =>
    date.toString() !== "Invalid Date" ? date : def;
  return (
    <NidNotification
      showAfterDate={validate(showAfterDate, new Date())}
      showUntilDate={validate(showUntilDate, new Date(Date.now() + ONE_DAY))}
    >
      <Box className={styles.notification}>
        <Markdown>{response.data.message}</Markdown>
      </Box>
    </NidNotification>
  );
};

export default Notification;
