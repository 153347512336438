import axios, { type AxiosResponse } from "axios";
import { OPERATION_API_NOTIFICATION } from "./apiPaths";

export interface NotificationResponse {
  message: string;
  show_after_date: string;
  show_until_date: string;
}

export const getNotification: () => Promise<
  AxiosResponse<NotificationResponse>
> = async () => {
  return axios.get(OPERATION_API_NOTIFICATION, { withCredentials: true });
};
