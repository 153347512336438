import { post } from "@/api/api-client";
import type { AxiosResponse } from "axios";

const url = import.meta.env.VITE_API_CONNECT_CONSENT_NIKKEI;

export type Request = {
  interaction_id: string;
  consent: {
    type: string;
    version: number;
  }[];
};

export type Response = {
  state: string;
};

export const postConsentNikkei = (
  interactionId: string,
  documents: {
    type: string;
    version: number;
  }[],
): Promise<AxiosResponse<Response>> => {
  return post<Request, Response>(url, {
    interaction_id: interactionId,
    consent: documents,
  });
};
