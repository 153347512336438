import { DialogLayout } from "@/components/ui/Layout";
import GrecaptchaMessage from "@/components/ui/Text/GrecaptchaMessage/GrecaptchaMessage";
import { InteractionIdState } from "@/contexts/RequestContext";
import { NikkeiConsentFeature } from "@/features/NikkeiConsentFeature/NikkeiConsentFeature";
import { useAtomValue } from "jotai";
import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

export const NikkeiConsentPage: React.FC = () => {
  const { t } = useTranslation("login");
  const { interactionId } = useAtomValue(InteractionIdState);

  return (
    <DialogLayout title={t("nikkei_consent.heading.h2")}>
      <NikkeiConsentFeature interactionId={interactionId} />
      <Box className="my-4">
        <GrecaptchaMessage />
      </Box>
    </DialogLayout>
  );
};
