import { useNikkeiConsentFeature } from "@/features/NikkeiConsentFeature/hooks/useNikkeiConsentFeature";
import { ContentSkeletonLoader } from "nid-common";
import { Box, Button, ExternalLinkIcon, Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import styles from "./NikkeiConsentFeature.module.css";

const showMoreHref = "show-more";

export const NikkeiConsentFeature: React.FC<{ interactionId: string }> = (
  props,
) => {
  const { t } = useTranslation("login");

  const { documents, isLoading, onSubmit, showFullSummary, onClickShowMore } =
    useNikkeiConsentFeature(props.interactionId);

  if (documents.length === 0 || props.interactionId === "") {
    return <ContentSkeletonLoader />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Typography>{t("nikkei_consent.text.description")}</Typography>
        <Box className={styles.outerBox}>
          {documents.map((document, index) => {
            const summaries = document.summary.split("<truncate>");
            return (
              <Box
                className={styles.document}
                key={document.type}
                data-testid={`document-${document.type}`}
              >
                <Link
                  size={"18"}
                  bold
                  href={document.url}
                  newTab
                  className={styles.documentTitleLink}
                  data-testid={`document-link-${document.type}`}
                >
                  {document.name}
                  <ExternalLinkIcon />
                </Link>

                <Markdown
                  components={{
                    a: ({ children, href }) => {
                      if (href === showMoreHref) {
                        return (
                          <button
                            className={styles.showMoreButton}
                            type={"button"}
                            onClick={(
                              e: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >,
                            ) => onClickShowMore(e, index)}
                          >
                            <Typography
                              size={"14"}
                              lineHeight={"180"}
                              color={"link"}
                            >
                              {children}
                            </Typography>
                          </button>
                        );
                      }
                      return (
                        <Link href={href} size={"14"} lineHeight={"180"} newTab>
                          {children}
                        </Link>
                      );
                    },
                    p: ({ children }) => {
                      return (
                        <Typography size={"14"} lineHeight={"180"}>
                          {children}
                        </Typography>
                      );
                    },
                  }}
                >
                  {!showFullSummary[index] && summaries.length > 1
                    ? `${summaries[0]}...[${t("nikkei_consent.button.show_more")}](${showMoreHref})`
                    : `${summaries.join("")}`}
                </Markdown>
              </Box>
            );
          })}
        </Box>

        <Typography size={"14"} className={"mb-6"}>
          {t("nikkei_consent.text.agreement")}
        </Typography>

        <Button disabled={isLoading} size={"full"}>
          {t("nikkei_consent.button.submit")}
        </Button>
      </form>
    </>
  );
};
