import type React from "react";

import { AlertMessage, type AlertMessageProps } from "nikkei-ui";

import { useSessionStorage } from "../../hooks/useSessionStorage";
import styles from "./Notification.module.css";

export type NotificationProps = {
  variant?: AlertMessageProps["variant"];
  storageKey?: string;
  storageInitValue?: boolean;
  showAfterDate?: Date;
  showUntilDate?: Date;
  children: React.ReactNode;
};

export const Notification: React.FC<NotificationProps> = ({
  variant = "danger",
  storageKey = "user-closed-notification",
  storageInitValue = false,
  showAfterDate = new Date("2024-09-06T10:00:00+09:00"),
  showUntilDate = new Date("2024-09-09T03:00:00+09:00"),
  children,
}) => {
  const showAfter = showAfterDate;
  const showUntil = showUntilDate;
  const now = new Date();

  const { value: userClosedNotification, setValue: setUserClosedNotification } =
    useSessionStorage(storageKey, storageInitValue);

  const open = showAfter <= now && now <= showUntil && !userClosedNotification;

  const handleClose = () => {
    setUserClosedNotification(true);
  };

  if (!open) {
    return <></>;
  }

  return (
    <AlertMessage
      variant={variant}
      onDismiss={handleClose}
      className={styles.notification}
    >
      {children}
    </AlertMessage>
  );
};
