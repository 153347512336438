import type React from "react";
import { Link, type LinkProps } from "../../Link";

type GrecaptchaMessageLinkProps = LinkProps & {
  keyType: "privacy" | "terms";
};

const GrecaptchaMessageLink: React.FC<GrecaptchaMessageLinkProps> = ({
  keyType = "privacy",
  children,
}) => {
  return (
    <Link
      key={
        keyType === "privacy"
          ? "google-privacy-policy"
          : "google-terms-of-service"
      }
      href={`https://policies.google.com/${keyType}`}
      type="text"
      newTab
      rel="noopener noreferrer"
      size="11"
    >
      {children}
    </Link>
  );
};

export default GrecaptchaMessageLink;
