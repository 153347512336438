import React from "react";

import { Outlet, type RouteObject } from "react-router";

import { Notification } from "@/components/ui/Notification";
import { NikkeiConsentPage } from "@/pages/Login/Consent/Nikkei";
import { InteractionProvider } from "@/providers/InteractionProvider";
import { IssuedWallProvider } from "@/providers/IssuedWallProvider";
import { AppLoaderProvider } from "@/providers/appLoaderProvider";
import { path } from ".";
import { ErrorBoundary } from "./ErrorBoundary";

const ErrorPage = React.lazy(() => import("@/pages/Dialog/Error/ErrorPage"));
const NotFoundPage = React.lazy(
  () => import("@/pages/Dialog/Error/NotFoundPage"),
);

const LoginAttributesPage = React.lazy(
  () => import("@/pages/Login/Attributes"),
);
const LoginConsentPage = React.lazy(() => import("@/pages/Login/Consent"));
const LoginIdPage = React.lazy(() => import("@/pages/Login/Id"));
const LoginPasskeyPage = React.lazy(() => import("@/pages/Login/Passkey"));
const LoginIndexPage = React.lazy(() => import("@/pages/Login/Index"));
const LoginMFAAuthenticatorAppCodePage = React.lazy(
  () => import("@/pages/Login/Mfa/AuthenticatorAppCode"),
);
const LoginMFABackupCodePage = React.lazy(
  () => import("@/pages/Login/Mfa/BackupCode"),
);

const ChallengeOtpPage = React.lazy(
  () => import("@/pages/Login/Mfa/ChallengeOtp"),
);
const LoginMFAEmailCodePage = React.lazy(
  () => import("@/pages/Login/Mfa/EmailCode"),
);
const LoginPasswordPage = React.lazy(() => import("@/pages/Login/Password"));

const RegisterStablePasswordCompletePage = React.lazy(
  () => import("@/pages/Login/RegisterStablePassword/Complete"),
);
const RegisterStablePasswordInputPage = React.lazy(
  () => import("@/pages/Login/RegisterStablePassword/Input"),
);
const SignupPage = React.lazy(() => import("@/pages/Signup/Index"));
const SignupRegistrationCompletedPage = React.lazy(
  () => import("@/pages/Signup/RegistrationCompleted"),
);
const SignupRegistrationEmailOtpPage = React.lazy(
  () => import("@/pages/Signup/RegistrationConfirm"),
);

const pageRoutes: RouteObject[] = [
  {
    path: path.login.root,
    element: (
      <IssuedWallProvider>
        <Outlet />
      </IssuedWallProvider>
    ),
    children: [
      { index: true, element: <LoginIndexPage /> },
      {
        element: (
          <InteractionProvider type="login">
            <Notification />
            <Outlet />
          </InteractionProvider>
        ),
        children: [
          {
            path: path.login.id,
            element: <LoginIdPage />,
          },
          {
            path: path.login.password,
            element: <LoginPasswordPage />,
          },
          {
            path: path.login.passkey,
            element: <LoginPasskeyPage />,
          },
          {
            path: path.login.challenge,
            element: <ChallengeOtpPage />,
          },
          {
            path: path.login.attributes,
            element: <LoginAttributesPage />,
          },
          {
            path: path.login.consent,
            element: <LoginConsentPage />,
          },
          {
            path: path.login.mfa.app,
            element: <LoginMFAAuthenticatorAppCodePage />,
          },
          {
            path: path.login.mfa.email,
            element: <LoginMFAEmailCodePage />,
          },
          {
            path: path.login.mfa.backupCode,
            element: <LoginMFABackupCodePage />,
          },
          {
            path: path.login.registerStablePassword.root,
            element: <RegisterStablePasswordInputPage />,
          },
          {
            path: path.login.registerStablePassword.complete,
            element: <RegisterStablePasswordCompletePage />,
          },
          {
            path: path.login.nikkeiConsent,
            element: <NikkeiConsentPage />,
          },
        ],
      },
    ],
  },
  {
    element: (
      <InteractionProvider type="signup">
        <Notification />
        <Outlet />
      </InteractionProvider>
    ),
    children: [
      {
        path: path.signup.start,
        element: <SignupPage />,
      },
      {
        path: path.signup.confirmEmail,
        element: <SignupRegistrationEmailOtpPage />,
      },
      {
        path: path.signup.complete,
        element: <SignupRegistrationCompletedPage />,
      },
    ],
  },

  {
    path: path.login.error,
    element: <ErrorPage />,
  },
  {
    path: path.signup.error,
    element: <ErrorPage />,
  },

  { path: "*", element: <NotFoundPage /> },
];

export const publicRoutes: RouteObject[] = [
  {
    errorElement: <ErrorBoundary />,
    path: "/",
    element: (
      <AppLoaderProvider>
        <Outlet />
      </AppLoaderProvider>
    ),
    children: pageRoutes,
  },
];
