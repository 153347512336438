import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router";

import type { ErrorResponse } from "@/@types/login";
import { authorizationConfirm } from "@/api/authorization-confirm";
import {
  AttributesState,
  ConsentState,
  NikkeiConsentState,
  SignupFinishedUrlState,
  appLoaderStateAtom,
  userFlowState,
} from "@/contexts/RequestContext";

import { path } from "@/routes";
import { useLeaveInteraction } from "./useLeaveInteraction";

export const useAuthorizationConfirm = () => {
  const navigate = useNavigate();
  const setAttributesState = useSetAtom(AttributesState);
  const setConsentState = useSetAtom(ConsentState);
  const setNikkeiConsentState = useSetAtom(NikkeiConsentState);
  const userFlow = useAtomValue(userFlowState);
  const setSignupFinishedUrlState = useSetAtom(SignupFinishedUrlState);
  const setIsLoading = useSetAtom(appLoaderStateAtom);
  const { redirect } = useLeaveInteraction();

  const call = async (interactionId: string): Promise<void> => {
    try {
      const confirmResponse = await authorizationConfirm(interactionId);

      if (
        (confirmResponse.data.state === "authorized" ||
          confirmResponse.data.state === "redirect_required") &&
        confirmResponse.data.location
      ) {
        switch (userFlow) {
          case "login":
            await redirect(confirmResponse.data.location);
            setIsLoading(false);
            return;
          case "signup": {
            setSignupFinishedUrlState(confirmResponse.data.location);
            navigate(path.signup.complete);
            return;
          }
        }
      }

      if (confirmResponse.data.state === "attribute_required") {
        setAttributesState({
          required: confirmResponse.data.required_attributes ?? [],
          current: confirmResponse.data.current_attributes ?? {},
        });
        navigate(path.login.attributes);
      } else if (confirmResponse.data.state === "consent_required") {
        setConsentState({
          companyName: confirmResponse.data.company_name,
          privacyPolicyLink: confirmResponse.data.privacy_policy_link,
          contents: confirmResponse.data.contents,
          purposeOfUse: confirmResponse.data.purpose_of_use,
        });
        navigate(path.login.consent);
      } else if (confirmResponse.data.state === "nikkei_consent_required") {
        setNikkeiConsentState({
          documents:
            confirmResponse.data.required_consent?.map((document) => {
              return {
                type: document.type,
                version: document.version,
                url: document.url,
                name: document.name,
                summary: document.summary,
              };
            }) ?? [],
        });
        navigate(path.login.nikkeiConsent);
      } else {
        navigate(path.login.error);
      }
    } catch (e) {
      if (
        (e as ErrorResponse).status === 403 &&
        (e as ErrorResponse).error === "unauthorized_user"
      ) {
        navigate(`${path.login.error}?code=unauthorized_user`);
      } else {
        navigate(path.login.error);
      }
    }
  };

  return {
    call,
  };
};
