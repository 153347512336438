import { AtlasTracking } from "nid-common";
import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router";
import { path } from ".";

export const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    AtlasTracking.unknownError(error);
    console.error(error);
    navigate(`${path.login.error}?code=system_error`);
  }, []);
  return null;
};
