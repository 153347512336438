import { postConsentNikkei } from "@/api/postConsentNikkei";
import {
  NikkeiConsentState,
  appLoaderStateAtom,
} from "@/contexts/RequestContext";
import { useAuthorizationConfirm } from "@/hooks/useAuthorizationConfirm";
import { path } from "@/routes";
import { useAtom, useAtomValue } from "jotai";
import { AtlasTracking } from "nid-common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const useNikkeiConsentFeature = (interactionId: string) => {
  const documentState = useAtomValue(NikkeiConsentState);
  const documents = [
    ...(documentState?.documents !== undefined ? documentState.documents : []),
  ];

  const [isLoading, setIsLoading] = useAtom(appLoaderStateAtom);

  const authorizationConfirm = useAuthorizationConfirm();
  const navigate = useNavigate();
  const [showFullSummary, setShowFullSummary] = useState(
    Array(documents.length).fill(false),
  );

  const onClickShowMore = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    setShowFullSummary((prev) => {
      const newShowFullSummary = [...prev];
      newShowFullSummary[index] = true;
      return newShowFullSummary;
    });
  };

  useEffect(() => {
    if (documents.length === 0 || interactionId === "") {
      navigate(path.login.error);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postConsentNikkei(
        interactionId,
        documents.map((d) => {
          return {
            type: d.type,
            version: d.version,
          };
        }),
      );
      if (response.data.state === "login_completed") {
        await authorizationConfirm.call(interactionId);
      } else {
        AtlasTracking.unknownError(response);
        navigate(path.login.error);
      }
    } catch (e) {
      AtlasTracking.unknownError(e);
      navigate(path.login.error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    documents,
    isLoading,
    onSubmit,
    showFullSummary,
    onClickShowMore,
  };
};
