import type React from "react";

import { Typography } from "nikkei-ui";
import GrecaptchaMessageLink from "nikkei-ui/components/Message/GrecaptchaMessage/GrecaptchaMessage";
import { Trans, useTranslation } from "react-i18next";

const GrecaptchaMessage: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <Typography size={"11"} color={"gray90"}>
      <Trans
        t={t}
        i18nKey="message.grecaptcha"
        components={[
          <GrecaptchaMessageLink
            key="google-privacy-policy"
            keyType="privacy"
          />,
          <GrecaptchaMessageLink
            key="google-terms-of-service"
            keyType="terms"
          />,
        ]}
      />
    </Typography>
  );
};

export default GrecaptchaMessage;
