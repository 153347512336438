import { post } from "@/api/api-client";

const url = import.meta.env.VITE_API_CONNECT_AUTHORIZATION_CONFIRM;

type State =
  | "authorized"
  | "redirect_required"
  | "attribute_required"
  | "consent_required"
  | "nikkei_consent_required";

export type RequiredAttributes = "name" | "birth" | "job_info";

export type CurrentAttributes = {
  LAST_NAME?: string;
  FIRST_NAME?: string;
  LAST_NAME_KANA?: string;
  FIRST_NAME_KANA?: string;
  BIRTH?: string;
  RESIDENCE_COUNTRY_FLAG?: string;
  ZIP_CODE?: string;
  RESIDENCE_COUNTRY_CODE?: string;
  OCCUPATION_NO?: string;
  BUSINESS_NO?: string;
  JOB_NO?: string;
  POSITION_NO?: string;
  EMPLOYEES_NO?: string;
};

export type CompanyName = {
  ja?: string;
  en?: string;
};

export type ConsentContents = {
  identifier?: boolean;
  email_address?: boolean;
  ja?: {
    basic?: Array<string>;
    address?: Array<string>;
    occupation?: Array<string>;
    others?: Array<string>;
  };
  en?: {
    basic?: Array<string>;
    address?: Array<string>;
    occupation?: Array<string>;
    others?: Array<string>;
  };
};

export type PurposeOfUse = {
  ja?: string;
  en?: string;
};

export type RequiredNikkeiConsent = {
  type: string;
  version: number;
  name: string;
  url: string;
  summary: string;
}[];

export interface Response {
  state: State;
  location: string | undefined;
  required_attributes: Array<RequiredAttributes> | undefined;
  current_attributes: CurrentAttributes | undefined;
  company_name: CompanyName | undefined;
  privacy_policy_link: string | undefined;
  contents: ConsentContents | undefined;
  purpose_of_use: PurposeOfUse | undefined;
  required_consent: RequiredNikkeiConsent | undefined;
}

export interface Request {
  interaction_id: string;
  mfa_skip?: boolean;
}

export const authorizationConfirm = (interactionId: string) => {
  const request = {
    interaction_id: interactionId,
  };
  return post<Request, Response>(url, request, {
    withCredentials: true,
  });
};
